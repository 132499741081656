import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import { H1 } from "../styles/TextStyles"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not found page" />
    <Wrapper>
      <Title>Ups, this page doesn't exist</Title>
      <Link to="/">Go back to the website</Link>
    </Wrapper>
  </Layout>
)

export default NotFoundPage

const Wrapper = styled.div`
  display: grid;
  gap: 30px;
  padding: 200px 80px;
  a {
    text-decoration: none;
    color: black;
    :hover {
      color: #5bccf6;
    }
  }
`
const Title = styled(H1)`
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 20px;
    line-height: 130%;
  }
`
